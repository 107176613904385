<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <h1>Admin - Manage Business Types</h1>
          <v-row>
            <v-col class="col-12 col-md-4">
              <v-text-field
                label="Search"
                v-model="searchTerm"
                clearable />
            </v-col>
            <v-col class="col-12 col-md-6">
              <v-btn
                @click="addBusinessType = true"
                small
                class="float-right">
                Add Business Type
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-8">
              <v-simple-table
                fixed-header
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Type</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(type, fIndex) in filteredBusinessTypes"
                      :key="fIndex"
                    >
                      <td>{{ type.text }}</td>
                      <td>
                        <v-btn small @click="archive(fIndex)">
                          Archive
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div
        class="modal"
        v-if="addBusinessType">
        <v-icon
          class="close"
          @click="addBusinessType = false"
        >mdi-close-circle</v-icon>
        <v-form>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newBusinessType"
                label="Business Type" />
            </v-col>
            <v-col class="pt-8">
              <v-btn
                @click="saveNewBusinessType" small>Save Business Type</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </template>
  
  <script>
  import axios from '../../../axios';
  
  export default {
    name: 'AdminBusinessTypes',
    computed: {
      token() {
        return this.$store.state.token;
      },
    },
    data() {
      return {
        businessTypes: [],
        filteredBusinessTypes: [],
        searchTerm: null,
        addBusinessType: false,
        newBusinessType: null,
      };
    },
    watch: {
      searchTerm() {
        this.filterBusinessTypes();
      },
    },
    methods: {
      archive(index) {
        const businessType = this.filteredBusinessTypes[index];
        axios.get(`/businessTypes/archive/${businessType.value}.json?token=${this.token}`)
          .then((response) => {
            this.businessTypes = response.data;
            this.filteredBusinessTypes = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      saveNewBusinessType() {
        const postData = {};
        postData.businessType = this.newBusinessType;
        axios.post(`/businessTypes/create/.json?token=${this.token}`, postData)
          .then((response) => {
            this.businesstypes = response.data;
            this.filteredBusinessTypes = response.data;
            this.addBusinessType = false;
            this.newBusinessType = null;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      filterBusinessTypes() {
        this.filteredBusinessTypes = this.businessTypes;
        if (this.searchTerm) {
          this.filteredBusinessTypes = this.businessTypes.filter((businessType) => businessType.text.toLowerCase().includes(
            this.searchTerm.toLowerCase(),
          ));
        }
      },
      getBusinessTypes() {
        axios.get(`/businessTypes/getAll.json?token=${this.token}&term=${this.searchTerm}`)
          .then((response) => {
            this.businessTypes = response.data;
            this.filteredBusinessTypes = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getBusinessTypes();
    },
  };
  </script>
  